import Inputmask from "inputmask";

document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll('.cnpj-mask').forEach((el)=>{
    el.addEventListener('input', function (e) {
      var x = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
      e.target.value = !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
    });
  });

  var phoneMasks = document.querySelectorAll(".phone-mask")
  var im = new Inputmask("(99) 9999-9999[9]");
  im.mask(phoneMasks);
})