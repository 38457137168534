import flatpickr from "flatpickr"
import { Portuguese } from "flatpickr/dist/l10n/pt"
import "flatpickr/dist/flatpickr.min.css"

Portuguese.rangeSeparator = " - "
document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll('.rangeCalendarFlatpickr').forEach((el)=>{
    flatpickr(el, {
      mode: "range",
      altInput: true,
      altFormat: "d/m/Y",
      dateFormat: "Y-m-d",
      locale: Portuguese,
    })
  });

  const simpleCalendar = document.getElementById('simpleCalendarFlatpickr')
  if (simpleCalendar) {
    flatpickr(simpleCalendar, {
      altInput: true,
      altFormat: "d/m/Y",
      dateFormat: "Y-m-d",
      allowInput: true,
      locale: Portuguese,
    })
  }
})