import PerfectScrollbar from "perfect-scrollbar";

var App = function() {
  var MediaSize = {
      xl: 1200,
      lg: 992,
      md: 991,
      sm: 576
  };
  const getDomElement = (querySelector) => {
    return document.querySelector(querySelector)
  }

  var categoryScroll = {
      scrollCat: function() {
          var sidebarWrapper = document.querySelectorAll('.sidebar-wrapper li.active')[0];
          if (!sidebarWrapper) return

          var sidebarWrapperTop = sidebarWrapper.offsetTop - 12;
          setTimeout(() => {
              const scroll = document.querySelector('.menu-categories');
              scroll.scrollTop = sidebarWrapperTop;
          }, 50);
      }
  }

  var toggleFunction = {
      sidebar: function($recentSubmenu) {

          var sidebarCollapseEle = document.querySelectorAll('.sidebarCollapse');

          sidebarCollapseEle.forEach(el => {
              el.addEventListener('click', function (sidebar) {
                  sidebar.preventDefault();
                  const getSidebar = document.querySelector('.sidebar-wrapper');

                  if ($recentSubmenu === true) {
                      if (document.querySelector('.collapse.submenu').classList.contains('show')) {
                          document.querySelector('.submenu.show').classList.add('mini-recent-submenu');
                          getSidebar.querySelector('.collapse.submenu').classList.remove('show');
                          getSidebar.querySelector('.collapse.submenu').classList.remove('show');
                          document.querySelector('.collapse.submenu').parentNode.querySelector('.dropdown-toggle').setAttribute('aria-expanded', 'false');
                      } else {
                          if (getDomElement(".main-container").classList.contains('sidebar-closed')) {
                              if (document.querySelector('.collapse.submenu').classList.contains('recent-submenu')) {
                                  getSidebar.querySelector('.collapse.submenu.recent-submenu').classList.add('show');
                                  document.querySelector('.collapse.submenu.recent-submenu').parentNode.querySelector('.dropdown-toggle').setAttribute('aria-expanded', 'true');
                                  document.querySelector('.submenu').classList.remove('mini-recent-submenu');
                              } else {
                                  document.querySelector('li.active .submenu').classList.add('recent-submenu');
                                  getSidebar.querySelector('.collapse.submenu.recent-submenu').classList.add('show');
                                  document.querySelector('.collapse.submenu.recent-submenu').parentNode.querySelector('.dropdown-toggle').setAttribute('aria-expanded', 'true');
                                  document.querySelector('.submenu').classList.remove('mini-recent-submenu');
                              }
                          }
                      }
                  }
                  getDomElement(".main-container").classList.toggle("sidebar-closed");
                  getDomElement(".header.navbar").classList.toggle('expand-header');
                  getDomElement(".main-container").classList.toggle("sbar-open");
                  getDomElement(".overlay").classList.toggle('show');
                  getDomElement('html, body').classList.toggle('sidebar-noneoverflow');
              });
          });
      },
      onToggleSidebarSubmenu: function() {
          ['mouseenter', 'mouseleave'].forEach(function(e){
              document.querySelector('.sidebar-wrapper').addEventListener(e, function() {
                  if (document.querySelector('body').classList.contains('alt-menu')) {
                      if (document.querySelector('.main-container').classList.contains('sidebar-closed')) {
                          if (e === 'mouseenter') {
                              const el = document.querySelector('li.menu .submenu')
                              if (el) el.classList.remove('show');
                              document.querySelector('li.menu.active .submenu').classList.add('recent-submenu');
                              document.querySelector('li.menu.active').querySelector('.collapse.submenu.recent-submenu').classList.add('show');
                              document.querySelector('.collapse.submenu.recent-submenu').parentNode.querySelector('.dropdown-toggle').setAttribute('aria-expanded', 'true');
                          } else if (e === 'mouseleave') {
                              const getMenuList = document.querySelectorAll('li.menu');
                              getMenuList.forEach(element => {

                                  var submenuShowEle = element.querySelector('.collapse.submenu.show');

                                  if (submenuShowEle) {
                                      submenuShowEle.classList.remove('show');
                                  }

                                  var submenuExpandedToggleEle = element.querySelector('.dropdown-toggle[aria-expanded="true"]');

                                  if (submenuExpandedToggleEle) {
                                      submenuExpandedToggleEle.setAttribute('aria-expanded', 'false');
                                  }
                                  
                              });
                          }
                      }
                  } else {
                      if (document.querySelector('.main-container').classList.contains('sidebar-closed')) {
                          if (e === 'mouseenter') {
                              const el = document.querySelector('li.menu .submenu')
                              if (el) el.classList.remove('show');

                              if (document.querySelector('li.menu.active .submenu')) {
                                  document.querySelector('li.menu.active .submenu').classList.add('recent-submenu');
                                  document.querySelector('li.menu.active').querySelector('.collapse.submenu.recent-submenu').classList.add('show');
                                  document.querySelector('.collapse.submenu.recent-submenu').parentNode.querySelector('.dropdown-toggle').setAttribute('aria-expanded', 'true');
                              }
                              
                          } else if (e === 'mouseleave') {
                              const getMenuList = document.querySelectorAll('li.menu');
                              getMenuList.forEach(element => {

                                  var submenuShowEle = element.querySelector('.collapse.submenu.show');

                                  if (submenuShowEle) {
                                      submenuShowEle.classList.remove('show');
                                  }


                                  var submenuExpandedToggleEle = element.querySelector('.dropdown-toggle[aria-expanded="true"]');

                                  if (submenuExpandedToggleEle) {
                                      submenuExpandedToggleEle.setAttribute('aria-expanded', 'false');
                                  }
                                  
                              });
                          }
                      }
                  }
                  
              });
          });

      },
      offToggleSidebarSubmenu: function () {
          // $('.sidebar-wrapper').off('mouseenter mouseleave');
      },
      overlay: function() {
          document.querySelector('#dismiss, .overlay').addEventListener('click', function () {
              // hide sidebar
              getDomElement(".main-container").classList.add('sidebar-closed');
              getDomElement(".main-container").classList.remove('sbar-open');
              // hide overlay
              getDomElement(".overlay").classList.remove('show');
              getDomElement('html, body').classList.remove('sidebar-noneoverflow');
          });            
      },
      search: function() {

          if (getDomElement(".toggle-search")) {
              
              getDomElement(".toggle-search").addEventListener('click', function(event) {
                  this.classList.add('show-search');
                  document.querySelector('body').classList.add('search-active');
              });
              
              document.querySelector('.search-close').addEventListener('click', function(event) {
                  event.stopPropagation();
                  getDomElement(".toggle-search").classList.remove('show-search');
                  document.querySelector('body').classList.remove('search-active');
                  document.querySelector('.search-form-control').value = ''
              });
          }

      },
  }

  var inBuiltfunctionality = {
      mainCatActivateScroll: function() {

          if (document.querySelector('.menu-categories')) {
          
              const ps = new PerfectScrollbar('.menu-categories', {
                  wheelSpeed:.5,
                  swipeEasing:!0,
                  minScrollbarLength:40,
                  maxScrollbarLength:300
              });

          }
      },
      notificationScroll: function() {

          if (document.querySelector('.notification-scroll')) {
              const notificationS = new PerfectScrollbar('.notification-scroll', {
                  wheelSpeed:.5,
                  swipeEasing:!0,
                  minScrollbarLength:40,
                  maxScrollbarLength:300
              });
          }
          
      },
      preventScrollBody: function() {
          var nonScrollableElement = document.querySelectorAll('#sidebar, .user-profile-dropdown .dropdown-menu, .notification-dropdown .dropdown-menu,  .language-dropdown .dropdown-menu')

          var preventScrolling = function(e) {
              e = e || window.event;
              if (e.preventDefault)
                  e.preventDefault();
              e.returnValue = false;  

              nonScrollableElement.scrollTop -= e. wheelDeltaY; 
          }

          nonScrollableElement.forEach(preventScroll => {

              preventScroll.addEventListener('mousewheel', preventScrolling);
              preventScroll.addEventListener('DOMMouseScroll', preventScrolling);
              
          });
      },
      searchKeyBind: function() {

          if (getDomElement(".toggle-search")) {
              Mousetrap.bind('ctrl+/', function() {
                  document.body.classList.add('search-active');
                  getDomElement(".toggle-search").classList.add('show-search');
                  getDomElement(".search-form-control").focus();
                  return false;
              });
          }

      },
      bsTooltip: function() {
          var bsTooltip = document.querySelectorAll('.bs-tooltip')
          for (let index = 0; index < bsTooltip.length; index++) {
              var tooltip = new bootstrap.Tooltip(bsTooltip[index])
          }
      },
      bsPopover: function() {
          var bsPopover = document.querySelectorAll('.bs-popover')
          for (let index = 0; index < bsPopover.length; index++) {
              var popover = new bootstrap.Popover(bsPopover[index])
          }
      },
      onCheckandChangeSidebarActiveClass: function() {
          if (document.body.classList.contains('alt-menu')) {
              if (document.querySelector('.sidebar-wrapper [aria-expanded="true"]')) {
                  document.querySelector('.sidebar-wrapper li.menu.active [aria-expanded="true"]').setAttribute('aria-expanded', 'false');
              }
          }
      },
      MaterialRippleEffect: function() {
          const getAllBtn = document.querySelectorAll('button.btn, a.btn');
          
          getAllBtn.forEach(btn => {
  
              if (!btn.classList.contains('_no--effects')) {
                  btn.classList.add('_effect--ripple');
              }
              
          });
  
          if (document.querySelector('._effect--ripple')) {
              Waves.attach('._effect--ripple', 'waves-light');
              Waves.init();
          }
      }
  }

  var _mobileResolution = {
      onRefresh: function() {
          var windowWidth = window.innerWidth;
          if ( windowWidth <= MediaSize.md ) {
              categoryScroll.scrollCat();
              toggleFunction.sidebar();
          }
      },
      
      onResize: function() {
          window.addEventListener('resize', function(event) {
              event.preventDefault();
              var windowWidth = window.innerWidth;
              if ( windowWidth <= MediaSize.md ) {
                  toggleFunction.offToggleSidebarSubmenu();
              }
          });
      }
      
  }

  var _desktopResolution = {
      onRefresh: function() {
          var windowWidth = window.innerWidth;
          if ( windowWidth > MediaSize.md ) {
              categoryScroll.scrollCat();
              toggleFunction.sidebar();
              toggleFunction.onToggleSidebarSubmenu();
          }
      },
      
      onResize: function() {
          window.addEventListener('resize', function(event) {
              event.preventDefault();
              var windowWidth = window.innerWidth;
              if ( windowWidth > MediaSize.md ) {
                  toggleFunction.onToggleSidebarSubmenu();
              }
          });
      }
      
  }

  function sidebarFunctionality() {
      function sidebarCloser() {

          if (window.innerWidth <= 991 ) {

              if (!document.querySelector('body').classList.contains('alt-menu')) {

                  getDomElement("#container").classList.add("sidebar-closed");
                  getDomElement(".overlay").classList.remove('show');
              } else {
                  getDomElement(".navbar").classList.remove("expand-header");
                  getDomElement(".overlay").classList.remove('show');
                  getDomElement("#container").classList.remove('sbar-open');
                  getDomElement('html, body').classList.remove('sidebar-noneoverflow');
              }

          } else if (window.innerWidth > 991 ) {

              if (!document.querySelector('body').classList.contains('alt-menu')) {

                  getDomElement("#container").classList.remove("sidebar-closed");
                  getDomElement(".navbar").classList.remove("expand-header");
                  getDomElement(".overlay").classList.remove('show');
                  getDomElement("#container").classList.remove('sbar-open');
                  getDomElement('html, body').classList.remove('sidebar-noneoverflow');
              } else {
                  getDomElement('html, body').classList.add('sidebar-noneoverflow');
                  getDomElement("#container").classList.add("sidebar-closed");
                  getDomElement(".navbar").classList.add("expand-header");
                  getDomElement(".overlay").classList.add('show');
                  getDomElement("#container").classList.add('sbar-open');

                  if (document.querySelector('.sidebar-wrapper [aria-expanded="true"]')) {
                      document.querySelector('.sidebar-wrapper [aria-expanded="true"]').parentNode.querySelector('.collapse').classList.remove('show');
                  }

              }
          }
      }

      function sidebarMobCheck() {
          if (window.innerWidth <= 991 ) {

              if ( document.querySelector('.main-container').classList.contains('sbar-open') ) {
                  return;
              } else {
                  sidebarCloser()
              }
          } else if (window.innerWidth > 991 ) {
              sidebarCloser();
          }
      }

      sidebarCloser();

      window.addEventListener('resize', function(event) {
          sidebarMobCheck();
      });

  }

  return {
      init: function(Layout) {
          toggleFunction.overlay();
          toggleFunction.search();
          
          /*
              Desktop Resoltion fn
          */
          _desktopResolution.onRefresh();
          _desktopResolution.onResize();

          /*
              Mobile Resoltion fn
          */
          _mobileResolution.onRefresh();
          _mobileResolution.onResize();

          sidebarFunctionality();

          /*
              In Built Functionality fn
          */
          inBuiltfunctionality.mainCatActivateScroll();
          inBuiltfunctionality.notificationScroll();
          inBuiltfunctionality.preventScrollBody();
          inBuiltfunctionality.searchKeyBind();
          inBuiltfunctionality.bsTooltip();
          inBuiltfunctionality.bsPopover();
          inBuiltfunctionality.onCheckandChangeSidebarActiveClass();
          inBuiltfunctionality.MaterialRippleEffect();
      }
  }

}();

window.addEventListener('load', function() {
  App.init('layout');
})