import TomSelect from "tom-select"

document.addEventListener("turbolinks:load", () => {
  const defaultOptions = {
    hidePlaceholder: true,
    placeholder: "Selecione",
    render: {
      no_results: () => {
        return '<div class="no-results">Não encontramos resultados</div>'
      },
      item: function(data, escape) {
        return '<div class="text-truncate" style="max-width: 200px">' + escape(data.text) + '</div>';
      },
    }
  }
  document.querySelectorAll('.custom-multi-select').forEach((el)=>{
     new TomSelect(el, defaultOptions);
  });
})

document.addEventListener("turbolinks:load", () => {
  const defaultOptions = {
    sortField: {
        field: "text",
        direction: "asc"
    },
    placeholder: 'Selecione',
    render: {
      no_results: () => {
        return '<div class="no-results">Não encontramos resultados</div>'
      },
      // item: function(data, escape) {
      //   return '<div class="text-truncate" style="max-width: 200px">' + escape(data.text) + '</div>';
      // },
    }
  }
  document.querySelectorAll('.custom-simple-select').forEach((el)=>{
     new TomSelect(el, defaultOptions);
  });
})